import { Popup } from "react-vant";
import { useState } from "react";
import { connect } from "react-redux";

import * as service from '../../service';

import "./report.css";


const ReportSelectDialog = (props)=>{
    
    const [showFree,setShowFree] = useState(true);
    const [showPay,setShowPay] = useState(false);

    return (
        <Popup 
            style={{
                width: 442,
                // height: 346,
                background: 'white',
                borderRadius: 8
            }}
            visible={props.visible}
            onClose = {()=>{
                props.onClose&&props.onClose();
            }}
            >
                <div style={{position:'relative',height:'95%'}} className='fx-box fx-fd-c'>
                    <div className="close-button fx-box fx-ai-c fx-jc-c btn" onClick={()=>{
                        props.onClose&&props.onClose();
                    }}>
                        <img src="/images/close.png"/>
                    </div>
                    <div className="fx-box h-s-top">
                        <div className={showFree?'c-c-active':'c-c-inactive'} style={{marginRight:26}} onClick={()=>{

                        }}>
                            举报
                        </div>
                    </div>
                    <div className="kycform select-form">
                        <div className="row" onClick = {()=>{
                            props.onSelect&&props.onSelect('垃圾广告')
                        }}>
                            <div className="fx-1">垃圾广告</div>
                            <div><img src="/images/right.png" className="nexticon"></img></div>
                        </div>
                        <div className="row"  onClick = {()=>{
                            props.onSelect&&props.onSelect('不实信息')
                        }}>
                            <div className="fx-1">不实信息</div>
                            <div><img src="/images/right.png" className="nexticon"></img></div>
                        </div>
                        <div className="row" onClick = {()=>{
                            props.onSelect&&props.onSelect('辱骂、人身攻击等行为')
                        }}>
                            <div className="fx-1">辱骂、人身攻击等行为</div>
                            <div><img src="/images/right.png" className="nexticon"></img></div>
                        </div>
                        <div className="row" onClick = {()=>{
                            props.onSelect&&props.onSelect('有害信息')
                        }}>
                            <div className="fx-1">有害信息</div>
                            <div><img src="/images/right.png" className="nexticon"></img></div>
                        </div>
                        <div className="row" onClick = {()=>{
                            props.onSelect&&props.onSelect('涉嫌侵权')
                        }}>
                            <div className="fx-1">涉嫌侵权</div>
                            <div><img src="/images/right.png" className="nexticon"></img></div>
                        </div>
                        <div className="row" onClick = {()=>{
                            props.onSelect&&props.onSelect('诱导赞同、关注等行为')
                        }}>
                            <div className="fx-1">诱导赞同、关注等行为</div>
                            <div><img src="/images/right.png" className="nexticon"></img></div>
                        </div>
                    </div>
                </div>
            </Popup>
    )
}

export default connect((state)=>{
    return {
        loginInfo:state.loginInfo,
        showLoginDialog:state.showLoginDialog
    }
},(dispatch)=>({dispatch}))(ReportSelectDialog);