import "./rule.css";

export const PrivacyDoc = (props)=>{
    return (
        <div className="rule-container">
<h1>            "UG圈"隐私说明</h1>

<p>UG圈（以下简称UG圈）非常重视对您的个人隐私保护，有时候我们需要某些信息才能为您提供您请求的服务，本隐私声明解释了这些情况下的数据收集和使用情况。</p>

<p>本隐私声明适用于UG圈的所有相关服务，随着UG圈服务范围的扩大，隐私声明的内容可由UG圈随时更新，且毋须另行通知。更新后的隐私声明一旦在网页上公布即有效代替原来的隐私声明。</p>

<h3>我们收集哪些信息</h3>

<p>通常，您能在匿名的状态下访问UG圈并获取信息。当我们需要能识别您的个人信息或者可以与您联系的信息时，我们会征求您的同意。通常，在您注册UG圈或申请开通新的功能时， 我们可能收集这些信息：姓名，Email地址，住址和电话号码，并征求您的确认。</p>

<h3>关于您的个人信息</h3>

<p>UG圈严格保护您个人信息的安全。我们使用各种安全技术和程序来保护您的个人信息不被未经授权的访问、使用或泄露。 UG圈会在法律要求或符合UG圈的相关服务条款、软件许可使用协议约定的情况下透露您的个人信息，或者有充分理由相信必须这样做才能：</p>

<p>(a) 满足法律或行政法规的明文规定，或者符合UG圈站适用的法律程序；</p>

<p>(b)符合UG圈相关服务条款、软件许可使用协议的约定；</p>

<p>(c) 保护UG圈的权利或财产；</p>

<p>(d) 在紧急情况下保护UG圈员工、UG圈产品或服务的用户或大众的个人安全。</p>

<p>UG圈不会未经您的允许将这些信息与第三方共享，本声明已经列出的上述情况除外。</p>

<h3>关于免责说明</h3>

<p>就下列相关事宜的发生，UG圈不承担任何法律责任：</p>

<p>* 由于您将用户密码告知他人或与他人共享注册帐户，由此导致的任何个人信息的泄露，或其他非因UG圈原因导致的个人信息的泄露；</p>

<p>* UG圈根据法律规定或政府相关政策要求提供您的个人信息；</p>

<p>* 任何第三方根据UG圈各服务条款及声明中所列明的情况使用您的个人信息，由此所产生的纠纷；</p>

<p>* 任何由于黑客攻击、电脑病毒侵入或政府管制而造成的暂时性网站关闭；</p>

<p>* 因不可抗力导致的任何后果；</p>

<p>* UG圈在各服务条款及声明中列明的使用方式或免责情形。</p>

        </div>
    )
}