import { Popup, Toast } from "react-vant";
import { useState } from "react";
import { connect } from "react-redux";

import * as service from '../../service';

import "./unlock.css";
import { useNavigate } from "react-router-dom";


const UnlockDialog = (props)=>{
    
    const navigate = useNavigate();
    return (
        <Popup 
            style={{
                width: 442,
                // height: 346,
                background: 'white',
                borderRadius: 8
            }}
            visible={props.visible}
            onClose = {()=>{
                props.onClose&&props.onClose();
            }}
            >
                <div style={{position:'relative',height:'95%'}} className='fx-box fx-fd-c'>
                    <div className="close-button fx-box fx-ai-c fx-jc-c btn" onClick={()=>{
                        props.onClose&&props.onClose();
                    }}>
                        <img src="/images/close.png"/>
                    </div>
                    <div className="fx-box fx-ai-c fx-jc-c">
                        <div className='title' style={{marginRight:26,marginLeft:26}} >
                            是否付费{props.price}金币查看解锁内容
                        </div>
                    </div>
                    <div className="unlock-form">
                        <div className="fx-box fx-ai-c fx-jc-c" style={{marginTop:'auto',padding:"20px 0px"}}> 
                            <div className=" register-submit fx-box fx-ai-c fx-jc-c color-white btn" 
                            onClick={()=>{
                                Toast.fail({message:'余额不足，请充值！',onOpened:()=>{
                                    navigate('/wallet');
                                }});
                            }}>
                                19999金币/永久 开通圈内会员
                            </div>
                        </div>
                        <div className="fx-box fx-ai-c fx-jc-c" style={{marginTop:'auto',padding:"20px 0px"}}> 
                            <div className="unlock-btn fx-box fx-ai-c fx-jc-c color-white btn" 
                            onClick={()=>{
                                Toast.fail({message:'余额不足，请充值！',onOpened:()=>{
                                    navigate('/wallet');
                                }});
                            }}>
                                 支付{props.price}金币解锁
                            </div>
                        </div>
                    </div> 
                </div>
            </Popup>
    )
}

export default connect((state)=>{
    return {
        loginInfo:state.loginInfo,
        showLoginDialog:state.showLoginDialog
    }
},(dispatch)=>({dispatch}))(UnlockDialog);