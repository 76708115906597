import React from 'react';
import { Link } from 'react-router-dom';
import './searchs.css';


const SearchHistory=(props)=>{
      return (
        <div className='search-history'>
              <div className='search-his-header fx-box fx-ai-c fx-jc-sb'>
                 <div>搜索历史</div>
                 <div onClick={()=>{
                  
                  props.onClear&&props.onClear();
                 }}>清空</div>
              </div>
              <div className='search-his'>
                  {
                     props.history?.map((c,idx)=>{
                        return (
                           <Link to={"/search/"+c}>
                              <div className='search-his-item fx-box fx-ai-c' key={'ci_'+idx}>
                                 <img src='/images/search-icon.png'/>
                                 <div>{c}</div>
                              </div>
                           </Link>
                        )
                     })
                  }
                  
              </div>
              
        </div>
      );
}
export default SearchHistory;