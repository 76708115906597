import "./rule.css";

export const ServiceDoc = (props)=>{
    return (
        <div className="rule-container">
            
<h1>服务条款</h1>

<h2>“UG圈”服务条款</h2>

<p>欢迎访问UG圈等UG圈产品以使用我们提供的产品及服务。</p>

<p>我们的服务由UG圈基于本用户协议而提供。请仔细阅读。我们向您（或称“用户”)提供的服务还需要您遵守其他条款及服 务准则（包括但不限于UG圈平台规则、服务说明及使用帮助等,简称“其他规则”），您应同时了解并予以一并接受。</p>

<h3>您同意本协议及其他规则的方式包括但不限于</h3>

<p>(1) 通过点击“我同意”或者其他方式接受；</p>

<p>(2) 通过电子邮件、电话、传真、即时通信等方式所作的口头或书面表示；</p>

<p>(3) 本协议及其他规则（包括但不限于服务说明及使用帮助等）中有“默认同意”条款，您点击同意或以其他形式实际使用了UG圈服务的；</p>

<p>(4) 其他您与我们均认可的方式。</p>

<h3>“UG圈”账户</h3>

<p>为了使用我们提供的服务，您需要注册一个“UG圈”账户，目前有以种方式来注册“UG圈”账户：</p>

<p>(1) 使用实名登记手机号，验证码注册登录方式，创建“UG圈”账号</p>

<h3>“UG圈”账户说明</h3>

<p>(1) 在您符合账户使用规则的前提下，您将获得该账户的使用权，您同意我们享有根据经营需要及业务规范（包括但不限于您未登录账户时间达一年以上或您存在其他违反本协 议约定的行为时）回收“UG圈”账户的权利。</p>

<p>(2) “UG圈”账户是我们识别您并向您提供服务的依据。如果您使用我们提供的服务，您应负责维护您的登录账户的保密性并限制第三方使用/访问您的计算机或移动设备，并 确保您的登录密码（如您设置的话）未向任何第三方透露。您不得有偿或无偿转让账户，以免产生纠纷。并且，您同意对在您的账户和密码下发生的所有活动承担责任。如果您 未满18周岁，您只能在父母或监护人的参与下使用服务。</p>

<p>(3)账户启用后，为确保用户在UG圈中以特定用户身份享受UG圈的针对性服务，特定用户身份（包括但不限于圈主）应通过另行同意UG圈发布和更新的特定用户身 份使用规则获得或放弃。特定用户身份将与“UG圈”账户及固定活动空间（“圈子”）进行绑定，但享有特定用户身份的“UG圈”账户仍应同时遵从本用户协议及相应规则。</p>

<p>(4)根据中国现行法律法规及规范性文件的规定，您同意您有义务确保您所提供的个人信息的真实性和有效性，同时也有义务在相关信息资料发生变更时及时通知我们进行更新。 若因您提供任何错误、不实、过时或不完整资料，或我们有合理理由怀疑该资料为错误、不实、过时或不完整的，我们有权暂停或终止对您提 供服务，或限制您使用我们所提供 服务的部分。</p>

        </div>
    )
}