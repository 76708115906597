import React,{useState} from 'react';
import { connect } from 'react-redux';
import { Popup } from 'react-vant';
import './charge.css';
import * as service from '../../service';

const Charge=(props)=>{
   const [amount,setAmount] = useState(50);
   const [showCustom,setShowCustom]  = useState(false);
     let [payname,setpayname]=useState('ali');//修改这里的值 切换支付方式
     let [payments,setpayments]=useState([
         {
            channelName:'ali',
            icon:require('../../images/ali-icon.png'),
            name:'支付宝'
         },
         {
            channelName:'wx',
            icon:require('../../images/wx-icon.png'),
            name:'微信'
         }

     ]);

     let [money,setmoney]=useState([
         {
            money:50,
            coin:500
         },
         {
            money:100,
            coin:1000
         },
         {
            money:150,
            coin:1500
         },
         {
            money:200,
            coin:2000
         },
         {
            money:300,
            coin:3000
         },
         {
            money:398,
            coin:3980
         },
         {
            money:400,
            coin:4000
         },
         {
            money:500,
            coin:5000
         },
         {
            money:600,
            coin:6000
         }, {
            money:700,
            coin:7000
         }, {
            money:800,
            coin:8000
         }, {
            money:988,
            coin:9880
         }, {
            money:1888,
            coin:18880,
         },
         {
           type:'custom'
         }
     ]);
     return (<div className='charge'>
             <div className='charge-title fx-box fx-ai-c'>
                 <img src='/images/wallet-icon.png'/>
                 钱包
             </div>
             <div className='charge-account-desc'>
               您的账户当前余额<span>{props?.profile?.balance}</span>金币
             </div>
             <div className='charge-account-info'>
                 <div className='fx-box fx-ai-c'>
                     <div className='charge-label'>充值账号：</div>
                     <div className='charge-text'>{props.profile?.username}</div>
                 </div>

                 <div className='fx-box fx-ai-c'>
                     <div className='charge-label'>充值方式：</div>
                     <div className='charge-payments fx-box'>
                         {
                            payments.map((item,idx)=>{
                                return (<div key={'ck_'+idx} className={item.channelName===payname?'charge-payment-item fx-box fx-ai-c fx-jc-sb  charge-payment-item-'+payname:'charge-payment-item fx-box fx-ai-c'}>
                                    <img alt={item.name} src={item.icon}/>
                                    <span>{item.name}</span>
                                </div>)
                            })
                         }
                        
                     </div>
                 </div>

                 <div className='fx-box'>
                     <div className='charge-label'>充值金额：</div>
                     <div className='charge-money'>
                         {
                            money.map((item,idx)=>{
                                 if(item.type==='custom')
                                 return (
                                      <div key={'cmk_'+idx} className='charge-money-item fx-box fx-fd-c fx-jc-c fx-ai-c' onClick={()=>{
                                       setShowCustom(true);
                                      }}>
                                      <div className='charge-money-num'>其他金额</div>
                                    </div>)
                                 else return(
                                    <div key={'cmk2_'+idx} className={'charge-money-item fx-box fx-fd-c fx-jc-c fx-ai-c' +(amount==item.money?' selected':'' )} onClick={()=>{
                                       setAmount(item.money);
                                    }}>
                                        <div className='charge-money-num'>{item.money}<span className='charge-money-unit'>元</span></div>
                                        <div className='charge-money-num'>{item.coin}<span className='charge-money-unit'>币</span></div>
                                    </div>
                                 )
                            })
                         }
                     </div>
                 </div>

             </div>

             <div className='payment-btn' onClick={()=>{
               service.recharge(amount).then(ret=>{
                  console.log(ret);
                  if(ret.code == 0&&ret.data.ticket){
                     window.open(ret.data.ticket,'_blank');
                  }
               })
             }}>
                 去支付
             </div>

             <div className='payment-desc'>
                 <div className='payment-desc-title'>温馨提示：</div>
                 <div className='payment-desc-content'>
<p>1.金币可用于购买付费圈子、购买付费内容、购买圈主微信（如果圈主设定）、解锁圈内全部内容；</p>
<p>2.圈子内容由圈主创建并维护， 购买后如需要退款，请在购买后3天内与圈主协商退款；</p>
<p>3.如想和圈主视频连线，请打赏圈主后，方可与圈主视频连线；</p>
<p>4.支付完毕之后请一定等待“自动跳转返回”页面，以避免到账延迟；</p>
<p>5.因近期网络诈骗频发，请您勿将充值二维码交给他人，或让他人进行充值。如您将充值二维码泄露给他人，可能会涉嫌刑事违法犯罪，届时我们将会协助公安机关提供您的相关信息配合调查；同时我们可能对您的账户进行处理，包括但不限于封禁账号，扣除涉案等额金币等方式。</p>                     
                 </div>
             </div>

             <Popup 
            style={{
                width: 498,
                height: 246,
                background: 'white',
                borderRadius: 8
            }}
            visible={showCustom}
            onClose = {()=>{
                setShowCustom(false);
            }}
            >
                <div style={{position:'relative',height:'95%'}} className='fx-box fx-fd-c'>
                    <div className="close-button fx-box fx-ai-c fx-jc-c btn" onClick={()=>{
                        setShowCustom(false)
                    }}>
                        <img src="/images/close.png"/>
                    </div>
                    <div className="fx-box h-s-top">
                        <div className='h-s-active' style={{marginRight:26}}>
                            自定义金额</div>
                    </div>
                            <div className="fx-box fx-ai-c" style={{padding:20}}>
                                <div className="money-label">
                                    金额
                                </div>
                                <input className="money-input fx-1" 
                                placeholder="请输入金额"
                                value={amount} onChange={(e)=>{
                                    setAmount(e.target.value);
                                }}/>
                            </div>
                           
                            
                            <div className="fx-box fx-ai-c fx-jc-c" style={{marginTop:'auto'}}> 
                                <div className=" register-submit fx-box fx-ai-c fx-jc-c color-white btn"
                                onClick={()=>{
                                    setShowCustom(false);
                                }}>
                                    确定
                                </div>
                            </div>
                        
                    
                </div>
            </Popup>
     </div>);
}

export default connect(state=>{
   return{
      profile:state.profile
   }
}) (Charge);
