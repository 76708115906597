import { Popup } from "react-vant";
import { useState } from "react";
import { connect } from "react-redux";

import * as service from '../../service';

import "./reward.css";


const RewardDialog = (props)=>{
    
    
    const [money,setMoney] = useState(1);
    return (
        <Popup 
            style={{
                width: 674,
                // height: 346,
                background: 'white',
                borderRadius: 8
            }}
            visible={props.visible}
            onClose = {()=>{
                props.onClose&&props.onClose();
            }}
            >
                <div style={{position:'relative',height:'95%'}} className='fx-box fx-fd-c'>
                    <div className="close-button fx-box fx-ai-c fx-jc-c btn" onClick={()=>{
                        props.onClose&&props.onClose();
                    }}>
                        <img src="/images/close.png"/>
                    </div>
                    <div className="fx-box h-s-top">
                        <div className='c-c-active' style={{marginRight:26}} >
                            请选择打赏金额
                        </div>
                    </div>
                    <div className="fx-box fx-ai-c fx-jc-c">
                    进行打赏有机会与圈主视频连线
                    </div>
                    <div className="reward-form">
                        <div className="fx-box">
                            <div className="label">
                                打赏金额:
                            </div>
                            <div className="fx-box fx-wrap">
                                <div className={"price-block "+(money==1?' selected':'')} onClick = {()=>{
                                    setMoney(1);
                                }}>
                                     1元
                                </div>
                                <div className={"price-block "+(money==6?' selected':'')} onClick = {()=>{
                                    setMoney(6);
                                }}>
                                6元
                                </div>
                                <div className={"price-block "+(money==12?' selected':'')}  onClick = {()=>{
                                    setMoney(12);
                                }}>
                                12元
                                </div>
                                <div className={"price-block "+(money==18?' selected':'')}  onClick = {()=>{
                                    setMoney(18);
                                }}>
                                18元
                                </div>
                                <div className={"price-block "+(money==30?' selected':'')}  onClick = {()=>{
                                    setMoney(30);
                                }}>
                                30元
                                </div>
                                <div className={"price-block "+(money==99?' selected':'')}  onClick = {()=>{
                                    setMoney(99);
                                }}>
                                99元
                                </div>
                                <div className={"price-block "+(money==168?' selected':'')}  onClick = {()=>{
                                    setMoney(168);
                                }}>
                                168元
                                </div>
                            </div>
                        </div>
                        <div className="fx-box custom">
                            <div>自定义金额:</div>
                            <div>
                                <input value={money} onChange={(e)=>{
                                        
                                        const m = e.target.value;
                                        if(!m)
                                        {
                                            setMoney(m);
                                            return;
                                        }
                                        try{
                                            const mnum = Number.parseInt(m);
                                            setMoney(mnum);
                                        }
                                        catch(err){

                                        }
                                    
                                }}></input>
                            </div>
                            <div style={{color:'#2974EB'}}>元</div>
                        </div>
                        <div className="fx-box fx-ai-c fx-jc-c" style={{marginTop:'auto',padding:"20px 0px"}}> 
                            <div className={" reward-submit fx-box fx-ai-c fx-jc-c color-white btn "+((money&&money>=1)?'':'disabled') }
                            onClick={()=>{
                                props.onPay&&props.onPay(money)
                            }}>
                                确定
                            </div>
                        </div>
                    </div> 
                </div>
            </Popup>
    )
}

export default connect((state)=>{
    return {
        loginInfo:state.loginInfo,
        showLoginDialog:state.showLoginDialog
    }
},(dispatch)=>({dispatch}))(RewardDialog);