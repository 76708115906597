export const Sign = (props)=>{
    return(
        <div className="header-sign">
            <div className="linkbtn" onClick={()=>{
                props.onCreate&&props.onCreate();
            }}>
                创建圈子
            </div>
            <div className="linkbtn" onClick={()=>{
                props.onLogin&&props.onLogin();
            }}>
                登录
            </div>
        </div>
    )
}