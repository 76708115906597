import { Popup, Toast } from "react-vant";
import { useState } from "react";
import { connect } from "react-redux";

import * as service from '../../service';

import "./my.css";


const WithdrawDialog = (props)=>{
    
    const [showFree,setShowFree] = useState(true);
    const [showPay,setShowPay] = useState(false);

    const [amount,setAmount] = useState(100);
    const [zfb,setZfb] = useState('');
    const [realname,setRealname] = useState('');
    const [code,setCode]  = useState('');

    const clear = ()=>{
        setRealname('');
        setAmount('');
        setCode('');
        setZfb('');
    }

    return (
        <Popup 
            style={{
                width: 442,
                // height: 346,
                background: 'white',
                borderRadius: 8
            }}
            visible={props.visible}
            onClose = {()=>{
                props.onClose&&props.onClose();
            }}
            >
                <div style={{position:'relative',height:'95%'}} className='fx-box fx-fd-c'>
                    <div className="close-button fx-box fx-ai-c fx-jc-c btn" onClick={()=>{
                        props.onClose&&props.onClose();
                    }}>
                        <img src="/images/close.png"/>
                    </div>
                    <div className="fx-box h-s-top">
                        <div className={showFree?'c-c-active':'c-c-inactive'} style={{marginRight:26}} >
                            提现申请
                        </div>
                        
                    </div>
                    
                    
                        <div className="withdraw-form">
                            <div className="fx-box row">
                                <div className="fx-1 label">提现金额</div>
                                <div>
                                    <input type="text" className="value-input"/>
                                </div>
                            </div>
                            <div className="fx-box row">
                                <div className="fx-1 label">支付宝账号</div>
                                <div className="fx-1">
                                    <input type="text" className="value-input"/>
                                </div>
                            </div>
                            <div className="fx-box row">
                                <div className="fx-1 label">收款人姓名</div>
                                <div>
                                    <input type="text" className="value-input"/>
                                </div>
                            </div>
                            <div className="fx-box row">
                                <div className="fx-1 label">手机号{props.profile?.username}</div>
                                <div>
                                    <input type="text" className="code-input"/>
                                </div>
                                <div onClick={()=>{
                                    service.getVerifyCode(
                                        {
                                            phone:       props.profile?.username,
                                            'country_code': '+86',
                                            digit: 6
                                        }
                                    ).then((ret)=>{
                                        Toast.success('短信发送成功！');
                                    })
                                }}>
                                    <div className="btn-getcode">获取验证码</div>
                                </div>
                            </div>
                            <div className="fx-box fx-ai-c fx-jc-c" style={{marginTop:'auto',padding:"20px 0px"}}> 
                                <div className=" register-submit fx-box fx-ai-c fx-jc-c color-white btn" 
                                onClick={()=>{
                                    try{
                                        if(Number.parseFloat(amount)<2000)return;
                                        service.withdraw(amount,realname,zfb).then(ret=>{
                                            console.info(ret);
                                            clear();
                                            props.onClose&&props.onClose();
                                            Toast.success('提现申请已经成功提交！');
                                        })
                                    }catch(err){

                                    }
                                    
                                }}>
                                    申请提现
                                </div>
                            </div>
                            <div className="withdraw-notice">
                            <p>注意事项：</p>
                            <p>请输入正确真实的信息，以防无法收到提现资金</p>
                            <p>最小提现金额为2000金币，提现时平台会收取3%提现手续费</p>
                            </div>
                        </div>

                            
                   
                        
                    
                </div>
            </Popup>
    )
}

export default connect((state)=>{
    return {
        loginInfo:state.loginInfo,
        profile:state.profile,
        showLoginDialog:state.showLoginDialog
    }
},(dispatch)=>({dispatch}))(WithdrawDialog);