import { Navigate, useLocation, useNavigate } from "react-router-dom"
import "./Header.css";

export const Menu = (props)=>{
    const navigate= useNavigate();
    const location = useLocation();
    return(
        <ul className="fx-box menu">
            <li className={"btn"+(location.pathname=='/'?' active':'-inactive')} onClick={()=>{
                navigate('/')
            }}>
                <img src={location.pathname=='/'?'/images/home-active.png':'/images/home-inactive.png'} className="menuitem-icon"/>推荐
            </li>
            <li className={"btn"+(location.pathname.startsWith('/moments')?' active':'-inactive')} onClick={()=>{
                navigate('/moments')
            }}>
                <img src={location.pathname=='/moments'?'/images/circle-active.png':'/images/circle-inactive.png'} className="menuitem-icon"/>圈子
            </li>
            <li className={"btn"+(location.pathname=='/feedback'?' active':'-inactive')} onClick={()=>{
                navigate('/feedback')
            }}>
                <img src={location.pathname=='/feedback'?'/images/feedback-active.png':'/images/feedback-inactive.png'} className="menuitem-icon"/>反馈
            </li>
        </ul>
    )
}