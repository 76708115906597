import React, { useState } from 'react';
import Pay from '../../components/Dialogs/Pay';
import { connect } from "react-redux";
import './feedback.css';
import * as service from '../../service';
import { Toast } from 'react-vant';

 const FeedBack=(props)=>{
      const [content,setContent] = useState('');
      return (<div className="feedback">
              <div className='fb-title'>
                  反馈
              </div>

              <div className='fb-container'>
                    <div className='fb-text'>
                        <textarea placeholder="请输入您要反馈的内容" value={content}
                        onChange = {(e)=>{
                            setContent(e.target.value)
                        }}>
                        </textarea>
                    </div>

                    <div className='fx-box fx-ai-c fx-jc-sb'>

                        {/**图片上传按钮**/}
                        <div className='fb-upload'>
                           
                           <div className='fb-upload-cxt'>
                             <img className='fb-upload-img' src='/images/fb-upload.png'></img>
                           </div>
                           <div  className='fb-upload-input'>
                              {/**图片上传按钮 由此拿到上传得到的文件信息**/}
                              <input type="file"/>
                           </div>
                        </div>


                        <div onClick={()=>{
                            service.feedback(content).then(ret=>{
                                Toast.success('反馈意见已经提交，感谢您的宝贵意见！');
                                setContent('');
                            }).catch(err=>{
                                console.error('[DEBUG]APP ERROR:',err)
                                props.dispatch({type:'SHOW_LOGIN_DIALOG'});
                            });
                        }} className='fb-button'>
                           提交反馈意见
                        </div>
                    </div>
              </div>
             

              

            <Pay  visible={false}/>
        </div>);
}
export default connect((state)=>{
    return {
        loginInfo:state.loginInfo,
        showPayDialog:state.showPayDialog
    }
},(dispatch)=>({dispatch}))(FeedBack);