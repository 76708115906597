import React, { useState } from 'react';
import { Popup } from "react-vant";
import { connect } from "react-redux";
import "./pay.css";
const Pay=(props)=>{
      console.log('get pay',props);

      let [showFree,setShowFree]=useState(false)

      let [money,setmoney]=useState([
        {
           money:50,
           coin:500
        },
        {
           money:100,
           coin:1000
        },
        {
           money:150,
           coin:1500
        },
        {
           money:200,
           coin:2000
        },
        {
           money:300,
           coin:3000
        },
        {
           money:400,
           coin:4000
        },
        {
           money:500,
           coin:5000
        },
        {
           money:600,
           coin:6000
        }, {
           money:700,
           coin:7000
        }, {
           money:800,
           coin:8000
        },
        {
          type:'custom'
        }
    ]);

    let [payname,setpayname]=useState('ali');//修改这里的值 切换支付方式
     let [payments,setpayments]=useState([
         {
            channelName:'ali',
            icon:require('../../images/ali-icon.png'),
            name:'支付宝'
         },
         {
            channelName:'wx',
            icon:require('../../images/wx-icon.png'),
            name:'微信'
         }

     ]);
      const onClick=()=>{
        console.log('get pay',props);
      }
      const onClose=()=>{
        props.dispatch({type:'CLOSE_PAY_INFO_DIALOG'})
      }
      return ( <Popup 
        style={{
            width: 782,
            height: 592,
            background: 'white',
            borderRadius: 8
        }}
        visible={props.showPayDialog}
        onClose = {()=>{
            onClose()
        }}
        >
            <div style={{position:'relative',height:'100%'}} className='fx-box fx-fd-c basic-theme-font'>
                        <div className="close-button fx-box fx-ai-c fx-jc-c btn" onClick={onClose}>
                            <img src="/images/close.png"/>
                        </div>


                        <div className="fx-box dialog-header">
                            <div className="c-c-inactive" style={{marginRight:26}} onClick={()=>{
                                
                            }}>
                                余额不足，请充值
                            </div>
                        
                       </div>

                       <div className='pay-items'>
                         <div className='fx-box fx-ai-c'>
                                <div className='charge-label'>当前余额：</div>
                                <div className='basic-theme-font font-color1 font-size26 fontw6 coin-text'>222<span className='fontw4 coin-text'>金币</span></div>
                         </div>
                         <div className='fx-box'>
                                    <div className='charge-label'>充值金额：</div>
                                    <div className='charge-money'>
                                        {
                                            money.map((item,idx)=>{
                                                return (
                                                    <>
                                                    {
                                                    item.type==='custom'?(<div key={idx} className='charge-money-item fx-box fx-fd-c fx-jc-c fx-ai-c'>
                                                    <div className='charge-money-num'>其他金额</div>
                                                    </div>):<div key={idx} className='charge-money-item fx-box fx-fd-c fx-jc-c fx-ai-c'>
                                                        <div className='charge-money-num'>{item.money}<span className='charge-money-unit'>元</span></div>
                                                        <div className='charge-money-num'>{item.coin}<span className='charge-money-unit'>币</span></div>
                                                    </div>
                                                    }
                                                    </>
                                                    
                                                )
                                            })
                                        }
                                    </div>
                          </div>

                          <div className='fx-box fx-ai-c'>
                                <div className='charge-label'>充值方式：</div>
                                <div className='charge-payments fx-box'>
                                    {
                                        payments.map((item,idx)=>{
                                            return (<div  key={idx} className={item.channelName===payname?'charge-payment-item fx-box fx-ai-c fx-jc-sb  charge-payment-item-'+payname:'charge-payment-item fx-box fx-ai-c'}>
                                                <img alt={item.name} src={item.icon}/>
                                                <span>{item.name}</span>
                                            </div>)
                                        })
                                    }
                                    
                                </div>
                         </div>


                       </div>

                       <div className='charge-button'>去支付</div>
            </div>
        </Popup>
           )
}


export default connect((state)=>{
    return {
        loginInfo:state.loginInfo,
        showPayDialog:state.showPayDialog
    }
},(dispatch)=>({dispatch}))(Pay);