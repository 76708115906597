import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Toast, Uploader } from 'react-vant';
import './userprofile.css';

import * as service from '../../service';

const UserProfile=(props)=>{
  // console.log('[DEBUG]profile:',props.profile);

  const [nickname,setNickname]  = useState(props.profile?.nickname||'');
  const [bio,setBio] = useState(props.profile?.bio||'');
  const [avatar,setAvatar] = useState(props.profile?.avatar);

  useEffect(()=>{
    console.log('[DEBUG]profile:',props.profile);
    if(props.profile){
      setNickname(props.profile?.nickname);
      setBio(props.profile?.bio);
      setAvatar(props.profile?.avatar);
    }
  },[props.profile]) 

  return (<div className='userprofile basic-theme-font'>
          <div className='user-avatar fx-box fx-ai-c'>
              <img src={avatar?avatar:'../../images/avatar.png'} alt=''/>
              
              <Uploader previewImage={false} 
              beforeRead = {(file)=>{
                console.log(file);
                service.uploadImage(file).then(ret=>{
                  
                  setAvatar(ret.data.url);
                });
                return false;
              }}
              >
                <div className='user-avatar-btn'>更换头像</div>
              </Uploader>
          </div>

          <div className='profile-info'>
          <div className='fx-box fx-ai-c'>
                <div className='profile-label'>用户ID</div>
                <div className='user-phone'>{props.profile?.userId}</div>
            </div>
            <div className='fx-box fx-ai-c'>
                <div className='profile-label'>用户昵称</div>
                <input className='user-nick' value={nickname} onChange={(e)=>{
                  setNickname(e.target.value);
                }}/>
            </div>
            <div className='fx-box fx-ai-c'>
                <div className='profile-label'>用户名称</div>
                <div className='user-phone'>{props.profile?.username}</div>
            </div>

            <div className='fx-box motto'>
                <div className='profile-label'>个人签名</div>
                <div className='profile-motto'>
                  <textarea value={bio} onChange={(e)=>{
                    setBio(e.target.value)
                  }}/>
                </div>
            </div>


          </div>

          <div className='profile-save-btn' onClick={()=>{
            service.updateProfile(nickname,avatar,bio).then(ret=>{
              Toast.success('个人资料修改提交成功，需等待审核！');
            })
          }}>
            保存
          </div>
  </div>);
}

export default  connect(state=>{
  return ({profile:state.profile})
})(UserProfile);