import { Popup, Toast, Uploader } from "react-vant";
import { useState } from "react";
import { connect } from "react-redux";

import * as service from '../../service';

import "./CreateCircle.css";


const LoginDialog = (props)=>{
    
    const [showFree,setShowFree] = useState(true);
    const [showPay,setShowPay] = useState(false);
    const [avatar,setAvatar] = useState(null);
    const [title,setTitle]  = useState('');
    const [bio,setBio] = useState('');
    const [price,setPrice] = useState(0);

    return (
        <Popup 
            style={{
                width: 442,
                // height: 346,
                background: 'white',
                borderRadius: 8
            }}
            visible={props.visible}
            onClose = {()=>{
                props.onClose&&props.onClose();
            }}
            >
                <div style={{position:'relative',height:'95%'}} className='fx-box fx-fd-c'>
                    <div className="close-button fx-box fx-ai-c fx-jc-c btn" onClick={()=>{
                        props.onClose&&props.onClose();
                    }}>
                        <img src="/images/close.png"/>
                    </div>
                    <div className="fx-box h-s-top">
                        <div className={showFree?'c-c-active':'c-c-inactive'} style={{marginRight:26}} onClick={()=>{
                            setShowFree(true);
                            setShowPay(false);
                        }}>
                            创建免费圈子
                        </div>
                        <div className={showPay?'c-c-active':'c-c-inactive'} style={{marginRight:26}} onClick={()=>{
                            setShowFree(false);
                            setShowPay(true);
                        }}>
                            创建付费圈子
                        </div>
                    </div>
                    {
                        showFree?<div className="c-c-form">
                            <div className="fx-box row">
                                <div className="fx-1 label">圈子名称</div>
                                <div>
                                    <input type="text" className="nameInput" value={title} onChange={(e)=>{
                                        setTitle(e.target.value);
                                    }}/>
                                </div>
                            </div>
                            <div className="row fx-ai-c">
                                <div className="label fx-1">圈子头像</div>
                                <div>
                                <Uploader previewImage={false} 
                                    beforeRead = {(file)=>{
                                        console.log(file);
                                        service.uploadImage(file).then(ret=>{
                                        
                                        setAvatar(ret.data.url);
                                        });
                                        return false;
                                    }}
                                    >
                                        <div className='fx-box fx-ai-c'>
                                            <img src={avatar??'/images/avatar-placeholder.png'} className="c-c-avatar"></img>
                                            <img src="/images/next.png" className="nextIcon"></img>
                                        </div>
                                    </Uploader>
                                </div>
                            </div>
                            <div className="fx-box fx-fd-c">
                                <div className="label">圈子介绍</div>
                                <div>
                                    <textarea value={bio} onChange={(e)=>{
                                        setBio(e.target.value);
                                    }}>

                                    </textarea>
                                </div>
                            </div>
                            <div className="fx-box fx-ai-c fx-jc-c" style={{marginTop:'auto',padding:"20px 0px"}}> 
                                <div className=" register-submit fx-box fx-ai-c fx-jc-c color-white btn" 
                                onClick={()=>{
                                     service.createCircle(title,avatar,bio).then(ret=>{
                                        console.log('[DEBUG]create CIRCLE:',ret);
                                        if(ret.code==0){
                                            Toast.success('创建申请已经提交,请耐心等待审核!');
                                            props.onClose&&props.onClose();
                                        }
                                     })
                                }}>
                                    创建圈子
                                </div>
                            </div>
                        </div>:null
                    }
                    {
                        showPay?<div className="c-c-form">
                             <div className="fx-box row">
                                <div className="fx-1 label">圈子名称</div>
                                <div>
                                    <input type="text" className="nameInput" value={title} onChange={(e)=>{
                                        setTitle(e.target.value);
                                    }}/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="label fx-1">圈子头像</div>
                                <div>
                                <Uploader previewImage={false} 
                                    beforeRead = {(file)=>{
                                        console.log(file);
                                        service.uploadImage(file).then(ret=>{
                                        
                                        setAvatar(ret.data.url);
                                        });
                                        return false;
                                    }}
                                    >
                                        <div className='fx-box fx-ai-c'>
                                            <img src={avatar?avatar:'/images/avatar-placeholder.png'} className="c-c-avatar"></img>
                                            <img src="/images/next.png" className="nextIcon"></img>
                                        </div>
                                    </Uploader>
                                </div>
                            </div>
                            <div className="fx-box fx-fd-c">
                                <div className="label">圈子介绍</div>
                                <div>
                                    <textarea value={bio} onChange={(e)=>{
                                        setBio(e.target.value);
                                    }}>

                                    </textarea>
                                </div>
                            </div>
                            <div className="fx-box row">
                                <div className="fx-1 label">圈子门票价格</div>
                                <div>
                                    <input type="text" className="priceInput" value={price} onChange={(e)=>{
                                        setPrice(e.target.value);
                                    }}/>
                                </div>
                                <div>金币</div>
                            </div>
                            <div className="fx-box fx-ai-c fx-jc-c" style={{marginTop:'auto',padding:"20px 0px"}}> 
                                <div className=" register-submit fx-box fx-ai-c fx-jc-c color-white btn" 
                                onClick={()=>{
                                    props.onCreatePayCircle&&props.onCreatePayCircle({
                                        title,avatar,bio,price,
                                    });
                                }}>
                                    创建圈子
                                </div>
                            </div>
                        </div>:null
                    }

                            
                   
                        
                    
                </div>
            </Popup>
    )
}

export default connect((state)=>{
    return {
        loginInfo:state.loginInfo,
        showLoginDialog:state.showLoginDialog
    }
},(dispatch)=>({dispatch}))(LoginDialog);