import "./rule.css";

export const RuleDoc = (props)=>{
    return (
        <div className="rule-container">
<h1>违规内容定义</h1>
<h2>1、任何违反国家法律法规的信息内容，包括但不限于：</h2>
<p>（1）危害国家统一、主 权和领土完整的内容；</p>
<p>（2）泄露国家秘密、危害国家安全或者损害国家荣誉和利益的内容；</p>
<p>（3）煽动民族仇恨、民族歧视，破坏民族团结的内容；</p>
<p>（4）宣扬淫秽、赌博、暴力或者教唆犯罪的内容；</p>
<p>（5）宣扬邪教的内容；</p>
<p>（6）散布谣言，扰乱社会秩序，破坏社会稳定的内容；</p>
<p>（7）侮辱或者诽谤他人，侵害他人合法权益的内容；</p>
<p>（8）不符合国家相关法律法规规定的广告；</p>
<p>（9）其他法律、行政法规和国家规定禁止的内容。</p>
<h2>2、违规内容发布范围</h2>
<p>平台内任何地方均不得发布上述违规内容，包括但不限于：圈子头像、圈子名称、圈子简介、圈主用户名称、圈主用户签名、圈子背景图、、评论、</p>
<h2>3、违规内容展现形式</h2>
<p>包括但不限于：图片、视频、音频、文字、文件及获取这些内容的工具、链接。</p>
<h2>处罚规范</h2>
<p>1、警告（四级）</p>
<p>通过系统消息、短信、邮件、客服微信等方式，对圈主不当行为进行提醒和告诫，圈主需在指定的时限内完成自查及整改。</p>
<p>2、限制功能（三级）</p>
<p>对圈子的部分功能进行限制使用，如禁止上传文件、禁止上传图片、视频等。</p>
<p>3、冻结结算（二级）</p>
<p>对圈主用户待结算资金进行一定期限的冻结。冻结期间，若该圈主用户创建的圈子没有继续发布违规内容，冻结期结束后即恢复结算，否则叠加冻结期限。</p>
<p>4、禁止入圈（一级）</p>
<p>若圈主无视警告，圈子内反复出现违规内容，或者圈子内容严重违规或造成恶劣影响的，对相应圈子进行禁止入圈的处罚，新用户将无法加入圈子。</p>
<p>圈主经过整改，有1次机会申请恢复，UG圈平台视整改情况决定是否恢复。恢复后的圈子若继续出现违规内容，将永久封圈。</p>
<p>若圈主发布严重违规的内容，平台可以直接永久封圈，没有申请恢复机会。请一定遵守国家和平台相关规定，合规合法的运营圈子！</p>
<h2>关于色情低俗内容的补充说明：</h2>
<p>为了给广大UG圈用户营造一个和谐、正能量的网络环境，禁止发布、传播一切色情低俗内容，包括但不限于:</p>
<p>1、直接暴露和描写人体性部位的内容。表现或隐晦表现性行为、具有挑逗性或者侮辱性的内容。以带有性暗示、性挑逗的语言描述性行为、性过程、 性方式的内容；</p>
<p>2、全身或者隐私部位未着衣物，仅用肢体掩盖隐私部位的内容。带有侵犯个人隐私性质的走光、偷 拍、漏点等内容。以庸俗和挑逗性标题吸引点击的内容；</p>
<p>3、相关部门禁止传播的色情和有伤社会风化的文字、音视频内容，包括一些电影的删节片段；</p>
<p>4、传播一 夜情、换 妻、性 虐待待等有伤伦理风化的内容；</p>
<p>5、黄 色动漫、小说。宣扬暴力、恶意谩骂、侮辱他人等的内容；</p>
<p>6、非法的性药品广告和性病治疗广告等相关内容；</p>
<p>7、 恶意传播侵害他人隐私的内容；</p>
<p>8、推介淫秽色 情和网上低俗信息的链接、图片、文字等内容。</p>
<strong>请全体圈主务必遵守以上条款，若违反相关条款，圈主将被冻结结算、封圈、清空圈子内容等处罚！</strong>
<strong>特别说明：为保护未成年人，UG圈平台对发布未成年人色情、暴力等内容的，将从重处罚！</strong>


        </div>
    )
}