import React, { useEffect, useState } from 'react';
import './moments.css';
import CardsWrap from '../../components/Cards/CardsWrap';
import MineCard from '../../components/Cards/MineCard';
import { connect } from 'react-redux';
import * as service from '../../service';
import { useNavigate, useParams } from 'react-router-dom';

const Moments=(props)=>{
  const navigate = useNavigate();
  const params = useParams();

  const [selected,setSelected]= useState(null);

  const [joined_circles,setJoinedCircles]=useState([]);
  const reload = ()=>{
    service.loadMyCircles().then(ret=>{
      console.info('[DEBUG]my circles:',ret.data);
      setJoinedCircles(ret.data.data);
    }).catch(err=>{
        console.error('[DEBUG]APP ERROR:',err)
        props.dispatch({type:'SHOW_LOGIN_DIALOG'});
    });
  }
  useEffect(()=>{
    service.loadMyCircles().then(ret=>{
      console.info('[DEBUG]my circles:',ret.data);
      setJoinedCircles(ret.data.data);
      if(!(params.id)){
        setSelected(ret.data.data[0]);
      }
      else{
        console.log('[DEBUG]find circle selected.')
        ret.data.data.map(c=>{
          if(c?.id==params.id)setSelected(c);
        })
      }
    }).catch(err=>{
        console.error('[DEBUG]APP ERROR:',err)
        props.dispatch({type:'SHOW_LOGIN_DIALOG'});
    });
    if(params.id==props.profile?.circle?.id)setSelected(props.profile?.circle);

  },[props.profile,params.id])

 
      return (
        <div className='fx-box'>
          <div className='mine-card-box'>
               <div>
                 <MineCard circles ={props.profile?.circle?[props.profile?.circle]:[]} 
                selected={selected}
                onSelect={(circle)=>{
                  console.log('[DEBUG]select:',circle);
                  setSelected(circle);
                  navigate('/moments/'+circle.id);
                }} 
                 title={'我创建的'}></MineCard>
               </div>
               <div>
                <MineCard  circles={joined_circles} title={'我加入的'}
                selected={selected}
                onSelect={(circle)=>{
                  console.log('[DEBUG]select:',circle);
                  setSelected(circle);
                  navigate('/moments/'+circle.id);
                }}></MineCard>
               </div>
          </div>

          {/**引入左侧卡片的盒子**/}
          {selected?<CardsWrap onLeave={()=>{
            setSelected(null);
            reload();
          }} circle={selected}/>:<div className='blankcard'>
                还没有创建/加入圈子
            </div>}
          
        </div>
      );
}


export default connect(state=>{
  return {
    profile:state.profile
  }
})(Moments);