import React,{useEffect, useState} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import SearchHistory from '../../components/Searchs/SearchHistory';
import SearchItem from '../../components/Searchs/SearchItem';
import './search.css';
import * as service from '../../service';

const Search=(props)=>{

      const params = useParams();
      console.log(params);
      
      let [searchdata,setsearchdata]=useState([
          {id:1},
          {id:2},
          {id:3},
          {id:5}
      ])
      const [searchs,setSearchs]  = useState([]);
      useEffect(()=>{
        const criteria = params.criteria;
        const searchstr=localStorage.getItem('search_cache')
        console.log('===>searchstr:',searchstr);
        const ns = searchstr?JSON.parse(searchstr):[];
        
        setSearchs(ns);

        ns.unshift(criteria);
        localStorage.setItem('search_cache',JSON.stringify(ns.slice(0,4)));

        service.search(criteria).then(ret=>{
          console.log('[DEBUG]search result:',ret.data.data);
          setsearchdata(ret.data.data);
        })        
      },[params.criteria])
      return (
        <div  style={{maxWidth: '1444px'}} className="fx-box search">
          <SearchHistory history={searchs} onClear={()=>{
            setSearchs([]);
            localStorage.removeItem('search_cache');
          }}></SearchHistory>
          <div className='search-list'>
              {
                searchdata.length===0?<div>暂无搜索结果</div>:searchdata.map((item,index)=>{
                  if(item.circle)
                    return (<SearchItem circle={item.circle} key={'si_'+index}/>)
                  else return null;
                })
              }
          </div>
        </div>
      );
}
export default Search;