import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './cards.css';
import * as service from '../../service';

const MineCard=(props)=>{
  
      
      return (
        <div className='mine-card'>
             <div className='basic-theme-font font-color2 font-size13'>{props.title}</div>
             <div className='mine-card-container font-color3'>
                 {
                  props.circles?.map((circle,idx)=>{
                    return (
                      <div className={circle?.id==props.selected?.id?'fx-box fx-ai-c current-circle':'fx-box fx-ai-c'} key={'ci_'+idx} onClick={()=>{
                        props.onSelect&&props.onSelect(circle)
                      }}>
                        <img alt="" src={circle?.avatar}/>
                        <span>{circle?.title}
                        </span>
                        {circle?.status=='pending'?<span className='circle-status-pending'>(审核中)</span>:null}
                    </div>
                    )
                  })
                    
                 }
                 
             </div>
        </div>
      );
}

export default connect(state=>{
  return {
    profile:state.profile
  }
})(MineCard);