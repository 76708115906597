import { Popup } from "react-vant";
import { useState } from "react";
import { connect } from "react-redux";

import * as service from '../../service';

import "./KYC.css";


const KYCDialog = (props)=>{
    
    const [showFree,setShowFree] = useState(true);
    const [showPay,setShowPay] = useState(false);
    const [realname,setRealname] = useState('');
    const [idcard_no,setIdcardNo] = useState('');

    return (
        <Popup 
            style={{
                width: 442,
                // height: 346,
                background: 'white',
                borderRadius: 8
            }}
            visible={props.visible}
            onClose = {()=>{
                props.onClose&&props.onClose();
            }}
            >
                <div style={{position:'relative',height:'95%'}} className='fx-box fx-fd-c'>
                    <div className="close-button fx-box fx-ai-c fx-jc-c btn" onClick={()=>{
                        props.onClose&&props.onClose();
                    }}>
                        <img src="/images/close.png"/>
                    </div>
                    <div className="fx-box h-s-top">
                        <div className={showFree?'c-c-active':'c-c-inactive'} style={{marginRight:26}} onClick={()=>{
                            setShowFree(true);
                            setShowPay(false);
                        }}>
                            创建付费圈子｜实名认证
                        </div>
                    </div>
                    <div className="kyc-form">
                        <div className="fx-box row">
                            <div className="fx-1 label">姓名</div>
                            <div>
                                <input type="text" className="nameInput" value={realname} onChange={(e)=>{
                                    setRealname(e.target.value);
                                }}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="label fx-1">身份证号</div>
                            <div>
                                <input type="text" className="nameInput" value={idcard_no} onChange={(e)=>{
                                    setIdcardNo(e.target.value)
                                }}/>
                            </div>
                        </div>
                        
                        <div className="fx-box fx-ai-c fx-jc-c" style={{marginTop:'auto',padding:"20px 0px"}}> 
                            <div className=" register-submit fx-box fx-ai-c fx-jc-c color-white btn" 
                            onClick={()=>{
                                const params = props.params;
                                Object.assign(params,{idcard_no,realname});
                                service.createPayCircle(params).then(ret=>{
                                    console.log('[DEBUG]create pay circle:',ret);
                                    props.onClose&&props.onClose();
                                })
                            }}>
                                确定
                            </div>
                        </div>
                    </div> 
                </div>
            </Popup>
    )
}

export default connect((state)=>{
    return {
        loginInfo:state.loginInfo,
        showLoginDialog:state.showLoginDialog
    }
},(dispatch)=>({dispatch}))(KYCDialog);