import { Popup } from "react-vant";
import { useState } from "react";
import { connect } from "react-redux";

import * as service from '../../service';

import "./report.css";


const ReportDialog = (props)=>{
    
    const [desc,setDesc]  = useState('');
    return (
        <Popup 
            style={{
                width: 442,
                // height: 346,
                background: 'white',
                borderRadius: 8
            }}
            visible={props.visible}
            onClose = {()=>{
                props.onClose&&props.onClose();
            }}
            >
                <div style={{position:'relative',height:'95%'}} className='fx-box fx-fd-c'>
                    <div className="close-button fx-box fx-ai-c fx-jc-c btn" onClick={()=>{
                        props.onClose&&props.onClose();
                    }}>
                        <img src="/images/close.png"/>
                    </div>
                    <div className="fx-box h-s-top">
                        <div className='c-c-active' style={{marginRight:26}} onClick={()=>{

                        }}>
                            {props.title??'诱导赞同、关注等行为'}
                        </div>
                    </div>
                    <div className="report-form">
                        <div className="fx-box row">
                            请描述举报理由
                        </div>
                        <div className="fx-box">
                            <textarea value={desc} onChange={(e)=>{
                                setDesc(e.target.value);
                            }}></textarea>
                        </div>
                        
                        <div className="fx-box fx-ai-c fx-jc-c" style={{marginTop:'auto',padding:"20px 0px"}}> 
                            <div className=" modal-cancel fx-box fx-ai-c fx-jc-c color-white btn" 
                            onClick={()=>{
                                props.onReturn&&props.onReturn();
                            }}>
                                返回
                            </div>
                            <div className=" register-submit fx-box fx-ai-c fx-jc-c color-white btn" 
                            onClick={()=>{
                                props.onSubmit&&props.onSubmit(desc);
                            }}>
                                举报
                            </div>
                        </div>
                    </div> 
                </div>
            </Popup>
    )
}

export default connect((state)=>{
    return {
        loginInfo:state.loginInfo,
        showLoginDialog:state.showLoginDialog
    }
},(dispatch)=>({dispatch}))(ReportDialog);