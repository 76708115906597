import { Link } from "react-router-dom";
import "./suggestion.css"

import * as service from '../../service';
import { useEffect,useState } from "react";

export const Suggestion=(props)=>{
    const [circles,setCircles] = useState(null);
    const [circlePage,setCirclePage] = useState(0);
    const onRefresh=()=>{
        service.loadCircles(circlePage).then(ret=>{
            setCircles(ret.data.data.data);
            setCirclePage(ret.data.data.next_page);
        })
    }
    useEffect(()=>{
        onRefresh();
    },[])
    return (
        <div className="suggestion">
                <div className="fx-box" style={{marginBottom:27}}>
                    <div className="fx-1 color-s">推荐圈子</div>
                    <div className="color-s refresh" onClick={()=>{
                        onRefresh();
                    }}>
                       <img src="/images/refresh.png" className="refresh"></img> 
                       换一批
                    </div>
                </div>
                <div className="items">
                    {
                        circles?.map((circle,idx)=>{
                            return (
                                <Link to={"/circle/"+circle.id} key={'lk_'+idx}><div className="item" key={"ck_"+idx}>
                                    <img src={circle.avatar}></img>
                                    <div  className="info">
                                        <div>{circle.title}</div>
                                        <div className="sub">{circle.bio}</div>
                                    </div>
                                </div>
                                </Link>            
                            )
                        })
                    }
                </div>
        </div>
    )
    
}