import { createStore } from 'redux'

const InitialState = {
    loginInfo:null,
    profile:null,
    showLoginDialog:false,
    currentGroup:null
}

const rootReducer=(state=InitialState,action)=>{
    // console.log('%c REDUX ACTION:%o','color:blue',action);
    switch(action.type){
        case 'LOGIN_OK':
            return Object.assign({},state,{loginInfo:action.payload,tim:null})
        case 'LOGOUT':
            localStorage.removeItem('oauth');
            return InitialState;
        case 'PROFILE':
            return Object.assign({},state,{profile:action.payload});
        case 'SHOW_LOGIN_DIALOG':
            return Object.assign({},state,{showLoginDialog:true});
        case 'CLOSE_LOGIN_DIALOG':
            return Object.assign({},state,{showLoginDialog:false});
        
        case 'SHOW_CREATE_DIALOG':
            return Object.assign({},state,{showCreateDialog:true});
        case 'CLOSE_CREATE_DIALOG':
            return Object.assign({},state,{showCreateDialog:false});

        case 'MESSAGE_RECEIVE':
            const messages1=state.messages.slice();
            messages1.push(action.payload);
            return Object.assign({},state,{messages:messages1})
        case 'MESSAGE_SEND':
            const messages2=state.messages.slice();
            messages2.push(action.payload);
            return Object.assign({},state,{messages:messages2})
        case 'JOIN_GROUP':
            return Object.assign({},state,{currentGroup:action.payload})
        case 'QUIT_GROUP':
                return Object.assign({},state,{currentGroup:null})
        case 'SHOW_PAY_INFO_DIALOG':
                return Object.assign({},state,{showPayDialog:true});
        case 'CLOSE_PAY_INFO_DIALOG':
                return Object.assign({},state,{showPayDialog:false});

        default:
            return state;
    }
}

export const store= createStore(rootReducer);