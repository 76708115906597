import React, { useEffect, useState } from 'react';
import Introduction from './Introduction';
import CardItem from './CardItem';
import './cards.css';
import * as service from '../../service';
import { Toast } from 'react-vant';

const MOCK_LIST=[

   {
      title:'',
      time:'',
      albums:[

      ],
      isfree:0,//0表示免费 1表示付费
      righticon:'arrow'
   },
   {
     title:'',
     time:'',
     albums:[

     ],
     isfree:1,//0表示免费 1表示付费
     righticon:'arrow'
  }
];


const CardWrap=(props)=>{
      let [tabindex,settabindex]=useState(0);
      let [tabs,settabs]=useState([
          {
             text:'全部',
          },
         //  {
         //    text:'视频'
         //  },
         //  {
         //    text:'图片'
         //  }
      ]);

      let [list,setlist]=useState([]);
      let [member,setMember] = useState(null);

      useEffect(()=>{
         service.loadCircle(props.circle?.id).then(ret=>{
            console.log('!!![DEBUG]circle:',ret.data);
            setlist(ret?.data?.feeds);
            setMember(ret?.data?.member);
         })
      },[props.circle])

      return (
        <div className='card-wrap'>
              {/**isother是控制 当前是否是查看他人圈子的参数 由此改变右侧icon**/}
              <Introduction  isother={false} circle={props.circle} onLeave={()=>{
               if(!props.circle||!props.circle?.id)return;

               service.leaveCircle(props.circle?.id).then(ret=>{
                  if(ret.code==0) Toast.success('您已离开当前圈子！');
                  props.onLeave&&props.onLeave(props?.circle?.id);
               })
              }}/>
              <div className='card-switch basic-theme-font fx-box'>
                  {
                     tabs.map((item,index)=>{
                        return (<div className='card-switch-item' key={'tk_'+index}>
                        <div className={tabindex===index?'font-color3 font-size13':'font-color4 font-size13'}>
                            {item.text}
                        </div>

                        {
                          tabindex===index?(<div className='card-switch-active'>
                        
                          </div>):<div></div>
                        }
                        
                     </div>)
                     })
                  }
              </div>

              <div className='cards-list'>
                 {
                  list?.map((item,idx)=>{
                     return (<CardItem member={member} item={item}  key={'cik_'+idx} />)
                  })
                 }
                 
              </div>
              
        </div>
      )
}

export default CardWrap;