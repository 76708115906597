import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { search } from "../../service";
import "./Header.css";

export const SearchInput = (props)=>{
    const navigate = useNavigate();
    const [criteria,setCriteria] = useState('');
    return (
        <div className="search-input-continer">
            <input className="search-input" placeholder="搜索圈主全称或ID" value={criteria}
            onKeyDown={(event)=>{
                if(event.keyCode==13){
                    navigate('/search/'+criteria);
                }
            }}
            onChange={e=>{
                setCriteria(e.target.value);
            }}
            ></input>
            <img src="/images/search.png" className="search-icon"  onClick={()=>{
                            navigate('/search/'+criteria)
                        }}/>
        </div>
    )
}